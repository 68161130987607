<template>
  <div
    @mouseover='showDashboardSelector=true'
    @mouseleave='showDashboardSelector=false'>
    <div class='cursor-pointer inline-block relative' style='max-width: 90vw;'>
      <h1 class='text-lg lg:text-3xl font-semibold inline-block mr-6 truncate w-full'>
        {{currentViewNameString}}
        <chevron-down-icon class='inline-block h-4 w-4' style='margin-top: -4px;'/>
      </h1>
      <div
        v-show='showDashboardSelector'
        class='dashboard-selector bg-white absolute border shadow-md rounded-md z-20 text-gray-700'>
        <div
          v-for='dashboardView in dashboardViews'
          :key='`dashboard-view-select-${dashboardView.id}`'
          @click='pickDashboard(dashboardView)'
          :class='isSelectedStyle(dashboardView.id)' 
          class='pl-4 pr-2 py-2 flex flex-row justify-between items-center hover:bg-gray-100 hover:text-gray-900'>
          <div class='truncate'>
            {{dashboardView.dashboard_view_name}}<span v-if='dashboardView.isEdited' class='text-red-600'>*</span>
            <label
              v-if='isPrivate(dashboardView)'
              class='inline-block ml-2 p-1 text-xs rounded text-gray-900 bg-gray-200' >
              {{ accessLabel(dashboardView) }}
            </label>
          </div>
          <button  
            @click='editDashboard(dashboardView)'
            v-if='dashboardView.editable'
            class='flex-shrink-0 px-3 py-2 block hover:font-medium rounded hover:text-gray-900 hover:bg-gray-200 border border-transparent hover:border-gray-400'>
            수정
          </button>
        </div>
        <button class='text-left border-t whitespace-no-wrap block w-full py-3 px-4 hover:font-medium hover:text-gray-900 hover:bg-gray-100' @click='createNewDashboard'>New View</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { ChevronDownIcon } from '@vue-hero-icons/outline'
import last                from 'lodash/last'

export default {
  name: 'DashboardChooserControls',
  components: {
    ChevronDownIcon,
  },
  data () {
    return {
      showDashboardSelector: false,
    }
  },
  computed: {
    ...mapState('dashboardViews', [
      'dashboardViews',
      'currentDashboardView',
    ]),
    currentViewNameString () {
      return (this.currentDashboardView.dashboard_view_name) ? this.currentDashboardView.dashboard_view_name : 'view'
    },
  },
  methods: {
    ...mapActions('dashboardViews', [
      'dashboardEditingOpen',
      'selectDashboardView',
      'createNewDashboardView',
    ]),
    accessLabel (dashboard) {
      return this.isPublic(dashboard) ? '공용' : ''
    },
    closeDashboardChooser () {
      this.showDashboardSelector = false
    },
    createNewDashboard () {
      this.createNewDashboardView()
      this.dashboardEditingOpen(last(this.dashboardViews))
      this.closeDashboardChooser()
    },
    editDashboard (dashboard) {
      this.dashboardEditingOpen(dashboard)
      this.closeDashboardChooser()
    },
    pickDashboard (dashboard) {
      this.selectDashboardView(dashboard)
      this.closeDashboardChooser()
    },
    isPrivate (dashboard) {
      return this.isPublic(dashboard)
    },
    isPublic (dashboard) {
      return dashboard.shared
    },
    isSelected (dashboardViewId) {
      return this.currentDashboardView.id === dashboardViewId
    },
    isSelectedStyle (dashboardViewId) {
      return this.isSelected(dashboardViewId) ? 'selected-dashboard' : ''
    },
  },
}
</script>

<style lang='scss' scoped>
.selected-dashboard {
  @apply bg-gray-100 font-semibold;
}

.dashboard-selector {
  @apply text-sm;
  width: 18rem;
}

@media (min-width: 1024px) {
  .dashboard-selector {
    @apply text-base;
    width: 22rem;
  }
}
</style>
