<template>
  <div class='px-2 lg:px-8 flex flex-row text-left overflow-x-auto mr-0'
    :style='desktopStyle'>
    <div v-for='group in groupingFieldValues'
      :key='`group-${group}`'
      class='mr-4 overflow-y-auto flex-shrink-0 overflow-x-visible'
      style='width: calc(16rem + 10px);'>
      <div class='text-lg mb-4 sticky top-0 z-10 bg-white'>
        <el-popover
          v-if='group === "해당없음"'
          placement='top-start'
          width='350'
          trigger='hover'
          content='해당 Group의 빈 속성을 가진 모든 항목이 여기로 이동합니다. 이 열은 제거할 수 없습니다.'>
          <h1 slot='reference' class='inline-block'>{{ group }}</h1>
        </el-popover>
        <h1 class='inline-block' v-else>{{ group }}</h1>
        <h5 class='inline-block ml-2 text-sm font-semibold text-gray-600'>{{investmentGroupCountString(group)}}</h5>
      </div>
      <dashboard-column :investments='investmentsByGrouping[group]' :group='group'></dashboard-column>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import DashboardColumn from '@/views/dashboard/DashboardColumn.vue'

export default {
  name: 'DashboardCardView',
  components: {
    DashboardColumn,
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapState('investments', [
      'investments',
    ]),
    groupByTemplate () {
      return this.currentDashboardView.group_by_custom_template
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    desktopStyle () {
      return (!this.isMobile) ? 'height: calc(100vh - 12rem); ' : 'height: calc(100vh - 8rem); '
    },
    groupingFieldValues () {
      let group = []
      if (this.groupByTemplate) {
        let values = this.groupByTemplate.selectable_values
        group = values.filter(field => this.currentDashboardView.group_by_values.includes(field)) // only 1 for now
      }
      if (this.currentDashboardView.show_group_by_value_unassigned) {
        group.push('해당없음')
      }
      return (this.currentDashboardView.group_by_custom_template_id > 0) ? group : []
    },
    investmentsByGrouping () {
      let grouped = this.groupingFieldValues.reduce((map, col) => {

        let colInvestments = this.investments.filter((investment) => {
          let investmentField = this.customFieldOnInvestment(investment)
          let values = this.valuesForInvestmentField(investmentField)

          if (col === '해당없음' && 
              this.currentDashboardView.group_by_custom_template && 
              this.currentDashboardView.show_group_by_value_unassigned) {
            // not in any col
            return values.every(val => !this.groupByTemplate.selectable_values.includes(val))
          } else {
            return values.some(val => val === col)
          }
        })

        // sort by card positions
        colInvestments.sort((a, b) => {
          let aPosition = a.positions.find(pos => pos.group_by_value === col)
          let bPosition = b.positions.find(pos => pos.group_by_value === col)
          if (aPosition && bPosition) {
            return aPosition.sort_index - bPosition.sort_index
          } else {
            return 0
          }
        })
        map[col] = colInvestments
        return map

      }, {})
      return grouped
    }
  },
  methods: {
    customFieldOnInvestment (investment) {
      return investment.custom_fields.find(custom_field => {
        return (custom_field.id === this.currentDashboardView.group_by_custom_template_id)
      })
    },
    valuesForInvestmentField (customFieldOnInvestment) {
      let values = []
      if (customFieldOnInvestment && customFieldOnInvestment.value !== null) {
        // multi select/checkbox exception
        if (customFieldOnInvestment.field_type === 'checkbox') {
          values = customFieldOnInvestment.value.split(',')
        } else {
          values = [customFieldOnInvestment.value]
        }
      }
      return values
    },
    investmentGroupCountString (group) {
      if (this.investmentsByGrouping[group]) {
        let length = this.investmentsByGrouping[group].length
        return (length > 0) ? `(${length})` : ''
      } else {
        return ''
      }
    },
  },
}
</script>
