<template>
  <div>
    <portal to='headerSpace'>
      <div class='dashboard-hide-for-print flex flex-row items-center justify-between lg:justify-start'>
        <dashboard-chooser-controls class='pt-2' />
        <button
          class='border py-1 px-2 uppercase lg:p-3 rounded-md border-gray-50 text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md flex flex-row justify-center items-center gap-x-1 lg:gap-x-2'
          @click='addNewInvestment()'>
          <plus-circle-icon />
          <span class='hidden lg:block'>Add Investment</span>
        </button>
      </div>
    </portal>
    <tabs-control v-if='showTabs'
      :tabs='dashboardTabs' 
      @selected-tab='selectedTab'
      class='dashboard-hide-for-print' />
    <dashboard-card-view     v-if='isCardView'         :style='addedPaddingForSidepanel' />
    <dashboard-list-view     v-else-if='isListView'  />
    <dashboard-weekly-view   v-else-if='isWeeklyView'  />
    <dashboard-calendar-view v-else-if='isCalendarView'  />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields }             from 'vuex-map-fields'
import { PlusCircleIcon }        from '@vue-hero-icons/outline'
import TabsControl               from '@/components/TabsControl.vue'
import DashboardChooserControls  from '@/views/dashboard/DashboardChooserControls.vue'
import DashboardCalendarView     from '@/views/dashboard/DashboardCalendarView.vue'
import DashboardCardView         from '@/views/dashboard/DashboardCardView.vue'
import DashboardListView         from '@/views/dashboard/DashboardListView.vue'
import DashboardWeeklyView       from '@/views/dashboard/DashboardWeeklyView.vue'
import EventBus                  from '@/utils/event-bus'

export default {
  name: 'Dashboard',
  components: {
    TabsControl,
    DashboardChooserControls,
    DashboardCalendarView,
    DashboardCardView,
    DashboardListView,
    DashboardWeeklyView,
    PlusCircleIcon,
  },
  computed: {
    ...mapState([
      'showSidepanel',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapState('investments', [
      'investments',
    ]),
    ...mapState('customFields', [
      'customFieldTemplates',
    ]),
    ...mapGetters('dashboardViews', [
      'dashboardTabs',
    ]),
    ...mapFields('dashboardViews', [
      'investmentStatus',
      'tabCustomFieldValue',
    ]),
    addedPaddingForSidepanel () { // magic numbers come from size of sidepanel, minus 48px (the width of margin, so that the content goes close to the edge of the sidepanel)
      return (this.showSidepanel) ?  'margin-right:63%' : ''
    },
    isListView () {
      return this.viewLayout.includes('List')
    },
    isCardView () {
      return this.viewLayout === 'Card'
    },
    isCalendarView () {
      return this.viewLayout === 'Monthly'
    },
    isWeeklyView () {
      return this.viewLayout === 'Weekly'
    },
    viewLayout () {
      return (this.currentDashboardView.view_layout) ? this.currentDashboardView.view_layout : ''
    },
    showTabs () {
      return this.currentDashboardView.tab_type && 
             (this.currentDashboardView.tab_type !== 'none') &&
             (this.dashboardTabs.length > 0)
    },
    missingInvestmentMessage () {
      return 'You do not have access to that particular investment or investment is missing on page.'
    },
  },
  methods: {
    ...mapActions('customFields', [
      'getCustomFieldTemplates',
    ]),
    ...mapActions('dashboardViews', [
      'getDashboardViews',
      'updateDashboardInvestments',
    ]),
    ...mapActions('investments', [
      'investmentDetailsOpen',
      'openNewInvestment',
    ]),
    addNewInvestment () {
      this.openNewInvestment()
    },
    openInvestmentIfQueryParams () {
      let queryParamInvestmentId = parseInt(this.$route.query.investment_id)
      if (queryParamInvestmentId) {
        let found = this.investments.find(investment => investment.id === queryParamInvestmentId)
        if (found) {
          this.investmentDetailsOpen(found)
        } else {
          this.$message({
            type: 'error',
            message: this.missingInvestmentMessage,
            onClose: () => {
              this.$router.replace({ name: 'Dashboard', query: null })
            }
          })
        }
      }
      EventBus.$off('updated-investment-list')
    },
    selectedTab (tab) {
      if (this.currentDashboardView.tab_type === 'status') {
        this.investmentStatus = tab
        this.updateDashboardInvestments()
      } if (this.currentDashboardView.tab_type === 'custom field') {
        this.tabCustomFieldValue = (tab !== 'All') ? tab : ''
        this.updateDashboardInvestments()
      } else {
        console.log(`tab updated to: ${tab}, when tab type is ${this.currentDashboardView.tab_type}`)
      }
    },
  },
  mounted () {
    this.getDashboardViews().then(() => {
      EventBus.$on('updated-investment-list', () => this.openInvestmentIfQueryParams())
    })
    this.getCustomFieldTemplates()
  },
  beforeDestroy () {
    EventBus.$off('updated-investment-list')
  }
}
</script>

<style lang='scss' scoped>

@media print {
  .dashboard-hide-for-print {
    display: none;
  }
}

</style>
