<template>
  <div
    class='mt-2 p-4 cursor-pointer rounded-lg text-left shadow-md bg-white'
    :class='isSelectedCard'
    @keydown.esc='sidepanelClose'>
    <div class='flex flex-row justify-start items-start'>
      <h4
        class='text-sm tracking-wide text-gray-900'
        style='font-weight: 500;'>
        {{investment.investment_name}}
      </h4>
      <el-popover
        v-if='hasApprovalRequests'
        placement='top-start'
        title='승인 필요'
        width='200'
        trigger='hover'
        class='ayo'
        :content='approvalRequestsString'>
        <el-badge
          :value='approvalRequestsCount'
          slot='reference'
          class='top-0 right-0'>
        </el-badge>
      </el-popover>
    </div>
    <div class='mb-2'>
      <span class='text-xs tracking-wide text-gray-700'>{{ investment.entity_name }}</span>
    </div>
    <div v-for='(customField, index) in displayOnCardFields'
      :key='`investment_custom_field-${customField.id}-${index}`'
      class='mt-1 text-xs tracking-wide text-gray-600'>
      <label class='inline-block opacity-75'>{{ customField.custom_field_name }}:</label>
      <div v-if='!isTableTypeAndValueNotNull(customField)' class='inline-block text-gray-800'>
        <el-popover v-if='isUserType(customField)'
          placement='top-start'
          title='상세 정보'
          width='300'
          trigger='hover'
          :content='userDetailsByField(customField)'>
          <span slot='reference'>{{ customFieldValue(customField) }}</span>
        </el-popover>
        <span v-else>{{ customFieldValue(customField) }}</span>
      </div>
      <custom-field-table v-if='isTableTypeAndValueNotNull(customField)' :field='customField' :is-view-mode='true'/>
    </div>
    <el-tooltip v-if='hasIncompleteFields'  :content='incompleteString' placement='top'>
      <div class='mt-4 inline-block'>
        <span class='font-semibold text-red-400 mr-1 text-sm'><exclamation-icon class='inline-block' style='margin-top: 0px;' />{{incompleteCount}}</span>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { ExclamationIcon }      from '@vue-hero-icons/outline'
import { mapActions, mapState, mapGetters } from 'vuex'
import numbro                   from 'numbro'
import CustomFieldTable         from '@/components/custom_fields/CustomFieldTable.vue'

export default {
  name: 'InvestmentCard',
  components: {
    ExclamationIcon,
    CustomFieldTable,
  },
  props: {
    investment: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    incompleteString () {
      return (this.hasIncompleteFields) ? `Incomplete fields: ${this.investment.incomplete_required_fields.toString()}` : ''
    },
    incompleteCount () {
      return (this.investment.incomplete_required_fields) ? this.investment.incomplete_required_fields.length : 0
    },
    isSelectedCard () {
      return (this.investment.id === this.drilldownInvestmentId) ? 'selected-investment' : ''
    },
    hasIncompleteFields () {
      return this.investment.incomplete_required_fields !== null && this.incompleteCount > 0
    },
    displayOnCardFields () {
      return (this.currentDashboardView)
             ? this.investment.custom_fields.filter(custom_field => this.customFieldHasAndRequiresValue(custom_field) && this.currentDashboardView.display_on_card_template_ids.includes(custom_field.id)).sort((a,b) => a.sort_index - b.sort_index)
             : []
    },
    approvalRequestsString () {
      let result = ''
      if (this.hasApprovalRequests) {
        this.investment.required_approval_requests.forEach((approvalRequest, index) => {
          result = `${result} ${index + 1}.${approvalRequest.title} \n`
        })
      }
      return result
    },
    approvalRequestsCount () {
      return (this.investment.required_approval_requests) ? this.investment.required_approval_requests.length : 0
    },
    hasApprovalRequests () {
      return this.approvalRequestsCount > 0
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    customFieldHasAndRequiresValue (customField) {
      return this.currentDashboardView.show_empty_display_on_values || customField.value
    },
    customFieldValue (customField) {
      return (customField.field_type === 'number' && customField.display_value) ? numbro(customField.display_value).format({ thousandSeparated: true }) : customField.display_value
    },
    isTableTypeAndValueNotNull (field) {
      return field.field_type === 'table' && field.value
    },
    isUserType (field) {
      return field.field_type === 'user' && field.value
    },
    userDetailsByField (field) {
      return field.metadata.users ?
        field.metadata.users.map(user =>
          `${user.name}: ${user.email}`
        ).join('\n') : ''
    }
  }
}
</script>

<style lang='scss' scoped>
.selected-investment {
  @apply transition duration-150 ease-in-out shadow-xl border;
  border-color: #60b4db;
  background-color: #fafdff;
}


</style>
