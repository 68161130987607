<template>
  <tr
    class='dashboard-listview-row text-sm cursor-pointer hover:bg-gray-200'
    :class='selectedRowStyle'
    @click='clickRowOpenInvestment($event)'>
    <td v-if='showName' class='border truncate px-2 py-1'>{{investment.investment_name}}</td>
    <td class='border truncate px-2 py-1'>{{investment.entity_name}}</td>
    <td v-for='(template, index) in sortedFields'
      class='border'
      :class='fieldCellCssClasses(template.field_type)'
      :key='`dashboard-list-row-${investment.id}-${template.templateId}-${index}`'
      style='height: 1px;'>
      <template v-if='template.field_type === "file"'>
        <div style='max-height:6rem; width:32rem;'>
          <custom-field-value v-for='field in template.groupedFields'
            :key='`list-row-${field.custom_field_value_id}`'
            :field='customFieldForEditOrView(field)'
            :is-view-mode='isViewMode'
            :hide-label='true'
            :style='customFieldStyleByType(template.field_type)'
            :handle-update-default='false'
            @update-value='customFieldValueUpdate'
            @click.stop='' />
        </div>
      </template>
      <template v-else>
        <custom-field-value v-for='field in template.groupedFields'
          :key='`list-row-${field.custom_field_value_id}`'
          :field='customFieldForEditOrView(field)'
          :is-view-mode='isViewMode'
          :hide-label='true'
          :style='customFieldStyleByType(template.field_type)'
          :handle-update-default='false'
          @update-value='customFieldValueUpdate'
          @click.stop='' />
      </template>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import CustomFieldValue from '@/components/custom_fields/CustomFieldValue.vue'
import groupBy   from 'lodash/groupBy'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'DashboardListRowView',
  components: {
    CustomFieldValue
  },
  props: {
    investment: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    isViewMode () {
      return this.currentDashboardView.view_layout === 'List View'
    },
    investmentCustomfields () {
      if (!this.currentDashboardView) {
        return []
      }
      return this.investment.custom_fields.filter(field => this.currentDashboardView.display_on_card_template_ids.includes(field.id))
    },
    selectedRowStyle () {
      return (this.investment.id === this.drilldownInvestmentId) ? 'bg-blue-100 font-semibold' : ''
    },
    sortedFields () {
      return Object.entries(groupBy(this.investmentCustomfields, 'id')).map(([key, fields]) => {
        return { templateId: parseInt(key), groupedFields: fields, field_type: fields[0].field_type, sort_index: fields[0].sort_index }
      }).sort((a, b) => a.sort_index - b.sort_index)
    },
    showID () {
      return this.currentDashboardView.show_list_view_id
    },
    showName () {
      return this.currentDashboardView.show_list_view_name
    },
  },
  methods: {
    ...mapActions ('investments', [
      'investmentDetailsOpen',
      'listUpdateAnyCustomFieldValue',
    ]),
    ...mapMutations ('investments', [
      'updateInvestmentFieldValue',
    ]),
    clickRowOpenInvestment (event) {
      let senderElementName = event.target.tagName.toLowerCase()
      let senderClassName   = event.target.className
      if (senderElementName === 'tr'   || 
          senderElementName === 'td'   || 
          senderElementName === 'span' ||
          (senderElementName === 'div' && !(senderClassName.includes('custom-field-option')) )) {
        this.investmentDetailsOpen(this.investment) 
      } else {
        console.log('ignore click on input elements. event: ', event)
      }
    },
    customFieldValueUpdate (customFieldValue) {
      let newFieldValue = Object.assign(customFieldValue, {
          investment_id: this.investment.id,
          entity_id:     this.investment.entity_id,
          changed:       true})
      this.listUpdateAnyCustomFieldValue(newFieldValue).then(resp => {
        this.updateInvestmentFieldValue(resp)
      })
    },
    customFieldStyleByType (type) {
      if (this.isViewMode && type !== 'table' && type !== 'file') {
        return 'max-width: 30rem; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'
      } else if (!this.isViewMode && type === 'paragraph') {
        return 'width: 30rem;'
      } else if (!this.isViewMode && type === 'user') {
        return 'width: 16rem;'
      } else if (!this.isViewMode && type === 'text') {
        return 'width: 16rem;'
      } else if (type === 'file') {
        return 'width: 16rem;'
      } else {
        return ''
      }
    },
    customFieldForEditOrView (customField) {
      if (this.isViewMode) {
        return customField
      } else {
        let field = cloneDeep(customField)
        field['location'] = 'table-row'
        return field
      }
    },
    fieldCellCssClasses (type) {
      let typeStyle = ''
      switch (type) {
        case 'number':
          typeStyle = 'text-right'
          break
        case 'file':
          typeStyle = 'overflow-auto'
          break
        case 'checkbox':
          typeStyle = 'text-center'
          break
        default:
          typeStyle = 'text-left'
      }
      let viewOrEditStyle = (this.isViewMode) ? 'px-2 py-1' : 'p-0 editing-cell'
      return `${typeStyle} ${viewOrEditStyle}`
    },
  }
}
</script>

<style lang='scss'>
  .dashboard-listview-row .editing-cell > div,
  .dashboard-listview-row .editing-cell input,
  .dashboard-listview-row .editing-cell select,
  .dashboard-listview-row .editing-cell textarea,
  .dashboard-listview-row .editing-cell > div > div,
  .dashboard-listview-row .editing-cell .el-input__inner,
  .dashboard-listview-row .editing-cell .el-input,
  .dashboard-listview-row .editing-cell .v-select.vs--searchable,
  .dashboard-listview-row .editing-cell .v-select.vs--searchable .vs__dropdown-toggle {
    @apply h-full w-full;
    min-height: 2.5rem;
  }


  .dashboard-listview-row .editing-cell textarea {
    @apply block border border-solid border-gray-300 leading-5;
  }

  .dashboard-listview-row .editing-cell .v-select {
    @apply bg-white;
  }

  .dashboard-listview-row .editing-cell .vs__selected-options {
    flex-direction: row;
  }

  .dashboard-listview-row .editing-cell .vs__selected {
    @apply py-1 gap-x-1;
    whitespace: nowrap;
    justify-content: space-between;
  }
</style>
