<template>
  <div 
    class='border-b pl-2 lg:pl-8 flex flex-row justify-start text-sm text-gray-600 overflow-x-auto'
    :class='tabContainerClassOverrides'>
    <div v-for='tab in tabs'
      :key='`tab-${tab}`'
      class='tab' 
      :class='selectedStyle(tab)'    
      @click='setActiveTab(tab)'>
      {{tab}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'TabsControl',
  components: {
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    defaultTab: {
      type: String,
      required: false,
    },
    tabContainerClassOverrides: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      activeTab: '',
    }
  },
  watch: {
    activeTab (newVal) {
      this.$emit('selected-tab', newVal)
    },
  },

  computed: {
  },
  methods: {
    setActiveTab (tab) {
      this.activeTab = tab
    },
    selectedStyle (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
  },
  mounted () {
    if (this.defaultTab) {
      this.activeTab = this.defaultTab
    } else {
      this.activeTab = this.tabs[0]
    }
  },
  beforeDestroy () {
  }
}
</script>

<style lang='scss' scoped>

  .tab {
    @apply px-4 pb-2 pt-4 uppercase tracking-wide cursor-pointer whitespace-no-wrap;
  }

  .tab:hover {
    @apply bg-gray-100;
  }

  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }

  @media (min-width: 1024px) {
    .tab {
      @apply px-8;
    }
  }
</style>
