<template>
  <div class='relative'>
    <button
      @click='toggleTypeFilters'
      class='uppercase text-sm px-4 py-2 flex flex-row items-center gap-x-1 hover:bg-gray-50 border border-solid rounded border-transparent hover:border-gray-200 hover:shadow-lg'>
      <span class='inline-block max-w-md truncate'>{{ filterTypeString }}</span>
      ({{clientScheduleTypeFilters.length}})
    </button>
    <div v-if='showTypeFilters'
      class='absolute left-0 border rounded-lg shadow-lg z-20 bg-white'
      style='top: 2.5rem;'>
      <div class='w-full h-full relative pt-8 pb-4 pl-4 pr-4'>
        <button
          @click='closeTypeFilters'
          class='absolute h-5'
          style='top: 0.5rem; right: 0.5rem;'>
        <x-icon class='inline-block text-gray-500 hover:text-gray-900 cursor-pointer h-4' />
        </button>
        <button
          v-if='selectedAll'
          @click='unselectAll'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2'>
          Unselect All
        </button>
        <button
          v-if='!selectedAll'
          @click='selectAll'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2'>
          Select All
        </button>
        <label v-for='filterType in selectableScheduleTypes'
          :key='`scheduleFilterType-${filterType}`'
          class='pl-2 pr-12 py-1 hover:bg-gray-200 cursor-pointer text-sm flex flex-row items-center justify-start gap-x-2'>
          <input
            type='checkbox'
            v-model='clientScheduleTypeFilters'
            :value='filterType' />
          <span class='whitespace-no-wrap'>{{ filterType }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState }  from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { XIcon }     from '@vue-hero-icons/outline'
import cloneDeep     from 'lodash/cloneDeep'

export default {
  name: 'DashboardCalendarTypeFilter',
  components: {
    XIcon,
  },
  data () {
    return {
      savedFilter: [],
      showTypeFilters: false,
    }
  },
  watch: {
    selectableScheduleTypes: {
      handler: function (newVal) {
        // will only change when dashboard changes
        let cookieVal = this.$cookies.get(`dashboard-${this.currentDashboardView.id}-calendar-types`)
        if (cookieVal && typeof(cookieVal) === 'string') {
          this.savedFilter = cookieVal.split(',') || []
        }

        if (this.savedFilter.length === 0) {
          this.clientScheduleTypeFilters = newVal
        } else {
          this.clientScheduleTypeFilters = newVal.filter(t => this.savedFilter.includes(t))
        }
      },
      immediate: true,
    },
    clientScheduleTypeFilters: {
      handler: function (newVal) {
        if (newVal && newVal.length > 0) {
          this.$cookies.set(`dashboard-${this.currentDashboardView.id}-calendar-types`, newVal)
        }
      },
    },
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapFields('schedules', [
      'clientScheduleTypeFilters',
    ]),
    filterTypeString () {
      if (this.selectableScheduleTypes.length === this.clientScheduleTypeFilters.length) {
        return '모든 스케줄'
      } else if (this.clientScheduleTypeFilters.length === 0) {
        return '스케줄'
      } else {
        return `${this.clientScheduleTypeFilters.join(', ')}`
      }
    },
    selectableScheduleTypes () {
      return this.currentDashboardView.schedule_types
    },
    selectedAll () {
      return this.clientScheduleTypeFilters.length === this.selectableScheduleTypes.length
    }
  },
  methods: {
    toggleTypeFilters () {
      this.showTypeFilters = !this.showTypeFilters
    },
    closeTypeFilters () {
      this.showTypeFilters = false
    },
    isSelectedType (optionLabel) {
      return this.clientScheduleTypeFilters.includes(optionLabel)
    },
    selectedTypeStyle (optionLabel) {
      return (this.isSelectedType(optionLabel)) ? 'opacity-50 font-normal' : 'font-medium ml-6'
    },
    selectAll () {
      this.clientScheduleTypeFilters = cloneDeep(this.selectableScheduleTypes)
    },
    unselectAll () {
      this.clientScheduleTypeFilters = []
    }
  },
}
</script>
