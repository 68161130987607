<template>
  <div
    class='dashboard-weekly-view'>
    <div class='date-and-filters absolute z-10 ml-8 flex flex-row gap-x-8' >
      <el-date-picker
        v-model='pickDate'
        type='date'
        value-format='yyyy-MM-dd'
        :picker-options='pickerOptions'
        :clearable='false'>
      </el-date-picker>
      <dashboard-calendar-type-filter />
    </div>
    <div class='weekly-day-column-container flex flex-row justify-start items-start'>
      <div v-for='(n, weekdayIndex) in numberOfDaysInWeek'
        :key='startOnMondayOffset(weekdayIndex)'
        class='weekly-view-column'
        :class='numberOfDaysInWeekClass'>
        <h2 class='day-of-week-title text-gray-800 font-medium flex flex-row justify-start items-center gap-x-1'>
          <span class='pl-2'>{{dayHeader(startOnMondayOffset(weekdayIndex))}}</span>
          <span class='font-light text-500 text-sm'>
            ({{dayOfWeekString(weekdayIndex)}})
          </span>
        </h2>
        <div class='weekly-day-column'>
          <dashboard-weekly-view-card v-for='(card, index) in formattedInvestmentsByWeekday(startOnMondayOffset(weekdayIndex))'
            :key='`${startOnMondayOffset(weekdayIndex)}-weekly-view-investment-${index}`'
            :card='card'
            :weekday-index='startOnMondayOffset(weekdayIndex)'
            class='mb-2 overflow-x-hidden cursor-pointer hover:shadow-lg'
            @click.native='investmentDetailsOpen(card.investment)'/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields }            from 'vuex-map-fields'
import dayjs        from 'dayjs'
import intersection from 'lodash/intersection'
import StringHelpers               from '@/utils/string-helpers'
import DashboardCalendarTypeFilter from '@/views/dashboard/DashboardCalendarTypeFilter.vue'
import DashboardWeeklyViewCard     from '@/views/dashboard/DashboardWeeklyViewCard.vue'

export default {
  name: 'DashboardWeeklyView',
  components: {
    DashboardCalendarTypeFilter,
    DashboardWeeklyViewCard,
  },
  data () {
    let initialDate = this.$router.currentRoute.query.selected_date
    return {
      pickDate: initialDate ? initialDate : dayjs().format('YYYY-MM-DD'),
      pickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  watch: {
    pickDate: {
      handler: function (newVal) {
        this.selectedDate    = newVal
        this.weeklyStartDate = dayjs(newVal).startOf('week').add(1, 'day').format('YYYY-MM-DD')
        this.weeklyEndDate   = dayjs(newVal).endOf('week').add(1, 'day').format('YYYY-MM-DD')

        if (this.$router.currentRoute.query.selected_date !== this.selectedDate) {
          let newParams = {
            ...this.$router.currentRoute.query,
            ...{
                 from_date:     this.weeklyStartDate,
                 to_date:       this.weeklyEndDate,
                 selected_date: this.selectedDate
               }
          }
          this.$router.replace({ query: newParams })
        }
        this.getFilteredInvestments({
          filters:   this.currentDashboardView.filters,
          from_date: this.weeklyStartDate,
          to_date:   this.weeklyEndDate
        })
      }
    }
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapState('investments', [
      'investments',
    ]),
    ...mapState('schedules', [
      'clientScheduleTypeFilters',
      'clientScheduleOwnerFilters',
    ]),
    ...mapFields('dashboardViews', [
      'selectedDate',
      'weeklyStartDate',
      'weeklyEndDate',
    ]),
    numberOfDaysInWeekClass () {
      return this.currentDashboardView.weekly_configurations.show_weekends ? 'full-week' : 'work-week'
    },
    numberOfDaysInWeek () {
      return this.currentDashboardView.weekly_configurations.show_weekends ? 7 : 5
    },
    displayByTemplateIds () {
      return this.currentDashboardView.calendar_field_template_ids
    },
    showOnCardTemplateIds () {
      return this.currentDashboardView.display_on_card_template_ids
    },
    formattedInvestmentsForCalendar () {
      let results = []
      this.investments.forEach(investment => {
        results = results.concat(this.makeCardByFields(investment))
                         .concat(this.makeCardBySchedules(investment))
      })
      return results.sort((a, b) => a.startTime.localeCompare(b.startTime))
    },
  },
  methods: {
    ...mapActions('investments', [
      'investmentDetailsOpen',
      'getFilteredInvestments',
    ]),
    startOnMondayOffset (index) {
      return (index + 1) % 7
    },
    dayOfWeekString (startDayOffset) {
      return dayjs(this.weeklyStartDate).add(startDayOffset, 'day').format('YYYY-MM-DD')
    },
    dayHeader (index) {
      return `${StringHelpers.dayStringForIndex(index)}요일`
    },
    formattedInvestmentsByWeekday (weekday) {
      return this.formattedInvestmentsForCalendar.filter(card => dayjs(card.startDate).day() === weekday)
    },
    selectedFields (customFields) {
     return customFields.filter(field => this.displayByTemplateIds.includes(field.id))
    },
    selectedShowOnCardFields (customFields) {
      return customFields.filter(field => field.value && this.showOnCardTemplateIds.includes(field.id))
    },
    selectedSchedules (schedules) {
     return schedules.filter(schedule => this.clientScheduleTypeFilters.includes(schedule.schedule_type))
    },
    dashboardDisplayOnCardString (investment) {
      let fieldsString = ''
      let valueString = ''
      this.selectedShowOnCardFields(investment.custom_fields).forEach(field => {
        valueString = field.value
        fieldsString += '\n' + field.custom_field_name + ': ' + valueString
      })
      return fieldsString
    },
    makeCardByFields (investment) {
      let fieldCardsOnCalendar = []
      let dates = []
      let tempResult = {}

      this.selectedFields(investment.custom_fields).forEach(field => {
        if (field.value) {
          tempResult = {investment_id: investment.id}
          tempResult['type'] = 'field'
          tempResult['investment'] = investment
          if (field.field_type === 'date-range') {
            dates = field.value.split(',')
              tempResult['startDate'] = dayjs(dates[0])
              tempResult['endDate'] = dayjs(dates[1])
          } else {
            tempResult['startDate'] = dayjs(field.value)
            tempResult['startTime'] = '00:00'
          }
          fieldCardsOnCalendar.push(tempResult)
        }
      })
      return fieldCardsOnCalendar
    },
    makeCardBySchedules (investment) {
      let scheduleCardsOnCalendar = []
      let tempResult = {}
      let filteredOwnerIds = this.clientScheduleOwnerFilters.map(owner => owner.id)
      let filteredByOwner = investment.schedules.filter(schedule => {
        return intersection(schedule.user_ids, filteredOwnerIds).length > 0 || (schedule.user_ids.length === 0 && filteredOwnerIds.includes(0))
      })
      this.selectedSchedules(filteredByOwner).forEach(schedule => {
        if (schedule.schedule_date) {
          tempResult = {investment_id: investment.id}
          tempResult['type'] = 'schedule'
          tempResult['schedule'] = schedule
          tempResult['investment'] = investment
          tempResult['startDate'] = dayjs(schedule.schedule_date)
          tempResult['startTime'] = schedule.start_time
          scheduleCardsOnCalendar.push(tempResult)
        }
      })
      return scheduleCardsOnCalendar
    },
  }
}
</script>

<style lang='scss'>

.weekly-view-column {
  min-width: 18rem;
}

.weekly-day-column-container {
  @apply pt-16 ml-8 gap-x-2;
}

.weekly-day-column {
  @apply overflow-y-auto;
  height: calc(100vh - 17rem);
}

@media print {
  .dashboard-weekly-view {
    @apply p-0;
    height: auto;
    overflow-x: visible;
  }
  .date-and-filters {
    display: none;
  }

  .weekly-view-column.full-week {
    width: calc(100vw / 7);
  }
  .weekly-view-column.work-week {
    width: calc(100vw / 5);
  }

  .weekly-day-column-container {
    @apply pt-2 ml-0 mx-2;
  }

  .weekly-day-column {
    overflow-y: visible;
    height: auto;
  }

  .day-of-week-title {
    font-size: 12px;
    font-weight: bold;
  }
}

@media (min-width: 1024px) {
  .dashboard-weekly-view {
    @apply px-8;
    height: calc(100vh - 12rem);
    overflow-x: auto;
  }
}
</style>
