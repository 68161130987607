<template>
  <div
    class='dashboard-list-view overflow-auto pl-2 lg:pl-8'
    :style='setupCssHeight'>
    <button
      class='p-3 text-blue-700 text-sm border border-transparent hover:border-blue-400 rounded-md'
      @click='excelDownload'>
      Excel download
    </button>
    <table>
      <thead>
        <tr class='text-sm font-normal uppercase'>
          <table-header-filter-text v-if='showName'
            v-bind:filter-text.sync='filterInvestment'
            :is-sorting-by='isSortingByThis(-2)'
            @clicked-field-label='listViewSortByField(-2)'
            :defaultLabel='investmentLabel'
            class='px-2 py-2 border sticky z-10 top-0 whitespace-no-wrap text-gray-700 text-left'
            style='background-color: #F6F9FC; min-width: 20rem;' />
          <table-header-filter-text
            v-bind:filter-text.sync='filterEntity'
            :is-sorting-by='isSortingByThis(-3)'
            @clicked-field-label='listViewSortByField(-3)'
            :defaultLabel='entityLabel'
            class='px-2 py-2 border sticky z-10 top-0 whitespace-no-wrap text-gray-700 text-left'
            style='background-color: #F6F9FC;' />
          <table-header-filter-text
            v-for='custom_field in dashboardTemplates'
            :key='`list-header-${custom_field.id}`'
            v-bind:filter-text='filterCustomFields[custom_field.id]'
            v-on:update:filterText='updatefilterCustomFields(custom_field.id, $event)'
            :is-sorting-by='isSortingByThis(custom_field.id)'
            @clicked-field-label='listViewSortByField(custom_field.id)'
            :default-label='custom_field.custom_field_name'
            class='px-2 py-2 border sticky z-10 top-0 whitespace-no-wrap text-gray-700'
            style='background-color: #F6F9FC; min-width: 10rem;'/>
        </tr>
      </thead>
      <tbody>
        <dashboard-list-row-view
          v-for='investment in sortedInvestments'
          :key='`dashboard-list-row-${investment.id}`'
          :investment='investment' />
      </tbody>
      <tbody v-if='emptyFilteredInvestments'>
        <tr>
          <td
            :colspan='tableColumnCount'
            class='px-4 py-8 text-center bg-gray-100 border text-gray-600'>
            There are no rows. Please check the filters.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import * as XLSX     from 'xlsx-js-style'
import dayjs     from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import DashboardListRowView  from '@/views/dashboard/DashboardListRowView.vue'
import TableHeaderFilterText from '@/components/TableHeaderFilterText.vue'
import StringHelpers         from '@/utils/string-helpers'

export default {
  name: 'DashboardListView',
  components: {
    TableHeaderFilterText,
    DashboardListRowView
  },
  data () {
    return {
      addInvestment: false,
      filterCaseNum: '',
      filterInvestment: '',
      filterEntity: '',
      filterCounterparty: '',
      filterCaseInfos: '',
      filterUsers: '',
      filterCustomFields: {},
      newInvestment: {investment_name: '', counterparty: ''},
    }
  },
  watch: {
    addInvestment: function (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          if (this.$refs.newInvestment) {
            this.$refs.newInvestment.$refs.investmentName.focus()
          }
        })
      }
    },
  },
  computed: {
    ...mapState('customFields',[
      'customFieldTemplates',
    ]),
    ...mapState('dashboardViews', [
      'dashboardSortByField',
      'dashboardSortByDirection',
      'currentDashboardView',
    ]),
    ...mapState('users', [
      'users',
    ]),
    ...mapState('funds', [
      'funds',
    ]),
    ...mapState('investments', [
      'investments',
    ]),
    ...mapState('contacts', [
      'contacts',
    ]),
    ...mapGetters('dashboardViews', [
      'currentDashboardHasTabs',
    ]),
    filteredInvestments () {
      if (this.filterEntity ===       '' &&
          this.filterCaseNum ===      '' &&
          this.filterInvestment ===   '' &&
          this.filterCounterparty === '' &&
          this.filterCaseInfos ===    '' &&
          this.filterUsers ===        '' &&
          Object.values(this.filterCustomFields).every(fieldValue => fieldValue === '')) {
        return this.investments
      } else {
        return this.investments.filter(investment =>
          (this.filterEntity === ''       || StringHelpers.stringIncludesInsensitive(investment.entity_name, this.filterEntity)) &&
          (this.filterCaseNum === ''      || StringHelpers.stringIncludesInsensitive(investment.casenum, this.filterCaseNum)) &&
          (this.filterInvestment === ''   || StringHelpers.stringIncludesInsensitive(investment.investment_name, this.filterInvestment)) &&
          (this.filterCounterparty === '' || StringHelpers.stringIncludesInsensitive(investment.counterparty, this.filterCounterparty)) &&
          (this.filterCaseInfos === ''    || StringHelpers.stringIncludesInsensitive(investment.case_infos, this.filterCaseInfos)) &&
          (this.filterUsers === ''        || StringHelpers.stringIncludesInsensitive(investment.owner_names, this.filterUsers)) &&
          Object.entries(this.filterCustomFields).every(([fieldId, filterStr]) => {
            let found = investment.custom_fields.find((field) => parseInt(field.id) === parseInt(fieldId))
            return (filterStr === '' || (found !== undefined && StringHelpers.stringIncludesInsensitive(String(found.value), filterStr)))
          })
        )
      }
    },
    sortedInvestments () {
      if (this.dashboardSortByField) {
        let sortedArray = cloneDeep(this.filteredInvestments)
        return sortedArray.sort((a, b) => {
          let first = {}
          let second = {}
          if (this.dashboardSortByField > 0) {
            first  = a.custom_fields.find(field => field.id === this.dashboardSortByField)
            second = b.custom_fields.find(field => field.id === this.dashboardSortByField)
          } else {
            first['value']  = a[`${this.fieldIdToColumn}`]
            second['value'] = b[`${this.fieldIdToColumn}`]
          }
          if ((!first  || !first.value) &&
              (!second && !second.value)) {
            return 0
          } else if (!first || !first.value) {
            return 1 * this.dashboardSortByDirection
          } else if (!second || !second.value) {
            return -1 * this.dashboardSortByDirection
          } else {
            console.log('value is: ', first.value)
            return first.value.localeCompare(second.value) * this.dashboardSortByDirection
          }
        })
      } else {
        return this.filteredInvestments
      }
    },
    emptyFilteredInvestments () {
      return (this.filteredInvestments.length === 0)
    },
    dashboardTemplates () {
      return this.customFieldTemplates.filter(template => this.currentDashboardView.display_on_card_template_ids.includes(template.id))
    },
    dashboardTemplatesIds () {
      return this.dashboardTemplates.map(template => template.id)
    },
    // this.customFieldTemplates 와 investments.custom_fields 는 API에서 'sort_index'로 order by 해서 보내준다. 수정시 순서 주의.
    filteredData () {
      let results = cloneDeep(this.filteredInvestments)
      results.forEach(investment => {
        investment['custom_fields'] = investment.custom_fields.filter(field => this.dashboardTemplatesIds.includes(field.id))
      })
      return results
    },
    excelFormat () {
      let results = []
      let temp = {}
      this.filteredData.forEach(row => {
        temp['INVESTMENT NAME'] = row.investment_name
        temp['회사'] = row.entity_name
        temp['담당자'] = row.owner_names
        row.custom_fields.forEach(field => {
          if (field.field_type === 'user') {
            temp[field.custom_field_name] = field.value ? field.value : ''
          } else if (field.field_type === 'contact') {
            temp[field.custom_field_name] = field.value ? field.value : ''
          } else if (field.field_type === 'fund') {
            temp[field.custom_field_name] = field.value ? field.value : ''
          } else {
            temp[field.custom_field_name] = field.value
          }
        })
        results.push(temp)
        temp = {}
      })
      return results
    },
    fieldIdToColumn () {
      // only for identifying columns that aren't
      switch (this.dashboardSortByField) {
        case -1:
        return 'casenum'
        case -2:
        return 'investment_name'
        case -3:
        return 'entity_name'
        case -4:
        return 'counterparty'
        case -5:
        return 'case_infos'
        default:
        return ''
      }
    },
    tableColumnCount () {
      let count = this.dashboardTemplates.length + 1
      if (this.showName) {
        count += 1
      }

      return count
    },
    investmentLabel () {
      return '명칭'
    },
    entityLabel () {
      return '회사'
    },
    showID () {
      return this.currentDashboardView.show_list_view_id
    },
    showName () {
      return this.currentDashboardView.show_list_view_name
    },
    setupCssHeight () {
      let heightWithOrWithoutTabs = (this.currentDashboardHasTabs) ? '9.5rem' : '8rem'
      return {
        '--largeScreenTableHeight': `${heightWithOrWithoutTabs}`,
        }
    },
  },
  methods: {
    ...mapActions('funds', [
      'getFunds',
    ]),
    ...mapActions('dashboardViews', [
      'listViewSortByField'
    ]),
    isSortingByThis (customFieldId) {
      if (customFieldId === this.dashboardSortByField) {
        return this.dashboardSortByDirection
      } else {
        return 0
      }
    },
    updatefilterCustomFields (customFieldId, filterValue) {
      this.$set(this.filterCustomFields, customFieldId, filterValue)
    },
    excelDownload () {
      var excelData = XLSX.utils.json_to_sheet(this.excelFormat)
      var workBook = XLSX.utils.book_new()
      let name = this.currentDashboardView.dashboard_view_name.replace(/[:/\\*?[\]]/g, '_')
      XLSX.utils.book_append_sheet(workBook, excelData, name)
      XLSX.writeFile(workBook, `${name}_${dayjs().format('YYYYMMDD')}.xlsx`)
    },
    userName (user) {
      return (user) ? `${user.name}` : ''
    },
    shortFundName (fund) {
      return (fund) ? `${fund.short_name}` : ''
    },
    contactName (contact) {
      return (contact) ? `${contact.contact_name}` : ''
    },
  },
  mounted () {
    this.getFunds()
  }
}
</script>

<style lang='scss' scoped>
  .dashboard-list-view {
    max-height: calc(100vh - 8rem);
  }

@media (min-width: 800px) {
  .dashboard-list-view {
    max-height: calc(100vh - var(--largeScreenTableHeight));
  }
}

</style>
