<template>
  <draggable
    v-model='columnInvestments'
    group='cards'
    :delay='300'
    :delay-on-touch-only='true'
    @change='groupChangeHandler($event, group)'
    style='min-height: 50%;' class='pb-12'>
    <investment-card v-for='investment in columnInvestments'
      :investment='investment'
      :key='`${group}-investment-${investment.id}`'
      style='width:16rem;'
      @click.native='investmentDetailsOpen(investment)'></investment-card>
  </draggable>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import InvestmentCard            from '@/views/dashboard/InvestmentCard.vue'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'
import api from '@/api/v1/investments'

export default {
  name: 'DashboardColumn',
  props: ['investments', 'group'],
  components: {
    draggable, InvestmentCard
  },
  data () {
    return {
      columnInvestments: [],
      newCardPositionSaving: false,
    }
  },
  watch: {
    investments: {
      handler: function () {
        this.columnInvestments = this.investments
        this.topCardRecentlyAdded(this.columnInvestments[0])
      }
    },
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
  },
  methods: {
    ...mapActions('investments', [
      'investmentDetailsOpen',
      'listUpdateInvestmentData',
      'listUpdateAnyCustomFieldValue',
    ]),
    ...mapMutations('investments', [
      'updateDashboardGroupByPosition',
      'updateInvestmentInInvestments'
    ]),
    groupChangeHandler (event, group) {
      if (event.added !== undefined && event.added.element !== undefined) {
        let newInvestment = cloneDeep(event.added.element)
        let field = newInvestment.custom_fields.find(custom_field => custom_field.id === this.currentDashboardView.group_by_custom_template_id)
        field.investment_id = newInvestment.id
        field.value = group
        field.changed = true
        this.updateInvestmentInInvestments(newInvestment)
        this.addToDashboardGroupByPositions(group, event.added.newIndex, newInvestment.id).then(() => {
          this.listUpdateAnyCustomFieldValue(field).then(() => {
            // update field then investment to get refreshed investment
            this.listUpdateInvestmentData(newInvestment)
          })
        })
      }
      if (event.removed !== undefined) {
        this.removeFromDashboardGroupByPositions(group, event.removed.oldIndex, event.removed.element.id)
      }
      if (event.moved !== undefined) {
        this.updateFromDashboardGroupByPositions(group, event.moved.oldIndex, event.moved.newIndex, event.moved.element.id)
      }
    },
    addToDashboardGroupByPositions (group, newIndex, investmentId) {
      // moving things down
      let params = []
      // columnInvestments may or maynot contain the new element at this point
      let offset = (this.columnInvestments[newIndex] && this.columnInvestments[newIndex].id === investmentId) ? 0 : 1
      if (offset > 0) {
        this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: investmentId, groupByValue: group, newIndex: newIndex })
        params.push({
          investment_id: investmentId,
          group_by_value: group,
          sort_index: newIndex
        })
      }
      params = params.concat(this.columnInvestments.slice(newIndex).map((investment, index) => {
        let iNewIndex = newIndex + index + offset
        this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: investment.id, groupByValue: group, newIndex: iNewIndex })
        return {
          investment_id: investment.id,
          group_by_value: group,
          sort_index: iNewIndex
        }
      }))
      return api.updateDashboardPositions({ dashboard_group_by_positions: params }, this.currentDashboardView.id)
    },
    // before the list has updated
    removeFromDashboardGroupByPositions (group, fromIndex, removeInvestmentId) {
      // moving things up
      let params = this.columnInvestments.slice(fromIndex + 1).map((investment, index) => {
        let newIndex = fromIndex + index
        this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: investment.id, groupByValue: group, newIndex: newIndex })
        return {
          investment_id: investment.id,
          group_by_value: group,
          sort_index: newIndex
        }
      })
      params.push({
        investment_id: removeInvestmentId,
        group_by_value: group,
        sort_index: null
      })
      this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: removeInvestmentId, groupByValue: group, newIndex: null })
      return api.updateDashboardPositions({ dashboard_group_by_positions: params }, this.currentDashboardView.id)
    },
    updateFromDashboardGroupByPositions (group, oldIndex, newIndex, investmentId) {
      let params = []
      let offset = (this.columnInvestments[newIndex] && this.columnInvestments[newIndex].id === investmentId) ? 0 : 1
      if (oldIndex > newIndex) {
        // shifting down
        params = params.concat(this.columnInvestments.slice(newIndex, oldIndex + 1).map((investment, index) => {
          let iNewIndex = newIndex + offset + index
          this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: investment.id, groupByValue: group, newIndex: iNewIndex })
          return {
            investment_id: investment.id,
            group_by_value: group,
            sort_index: iNewIndex
          }
        }))
        if (offset > 0) {
          // when not included
          params.push({
            investment_id: investmentId,
            group_by_value: group,
            sort_index: newIndex
          })
        }
        this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: investmentId, groupByValue: group, newIndex: newIndex })
      } else if (oldIndex < newIndex) {
        // shifting up
        params = params.concat(this.columnInvestments.slice(oldIndex + offset, newIndex + offset + 1).map((investment, index) => {
          let iNewIndex = oldIndex + index
          this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: investment.id, groupByValue: group, newIndex: iNewIndex })
          return {
            investment_id: investment.id,
            group_by_value: group,
            sort_index: iNewIndex
          }
        }))
        if (offset > 0) {
          params.push({
            investment_id: investmentId,
            group_by_value: group,
            sort_index: newIndex
          })
        }
        this.updateDashboardGroupByPosition({ dashboardViewId: this.currentDashboardView.id, investmentId: investmentId, groupByValue: group, newIndex: newIndex })
      }
      return api.updateDashboardPositions({ dashboard_group_by_positions: params }, this.currentDashboardView.id)
    },
    topCardRecentlyAdded (investment) {
      // dont care about no group, make sure only triggers once when doesnt have any position
      if (this.group !== '해당없음' &&
        !this.newCardPositionSaving &&
        investment &&
        investment.positions &&
        investment.positions.some(pos => pos.dashboard_view_id === null)) {
        this.newCardPositionSaving = true
        this.addToDashboardGroupByPositions(this.group, 0, investment.id).finally(() => {
          this.newCardPositionSaving = false
        })
      }
    },
  }
}
</script>
