<template>
  <div
    class='dashboard-calendar-view lg:px-8'
    style='height: calc(100vh - 12rem); overflow-x: auto;'>
    <dashboard-calendar-type-filter
      class='absolute z-10 w-1/2'
      style='right: 2rem; top: 6rem;' />
    <calendar-view
      :period-changed-callback='updateDates'
      :show-date='showDate'
      locale='ko-KR'
      currentPeriodLabel='오늘'
      :items='formattedInvestmentsForCalendar'
      @click-item='updateEvent'
      :itemContentHeight='heightByShowFields'
      itemTop='2.4rem'
      class='font-normal font-gray-900 calendar-view'>
      <calendar-view-header
        slot='header'
        slot-scope='t'
        :header-props='t.headerProps'
        @input='setShowDate'/>
    </calendar-view>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapFields }                        from 'vuex-map-fields'
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
import dayjs        from 'dayjs'
import intersection from 'lodash/intersection'

import DashboardCalendarTypeFilter from '@/views/dashboard/DashboardCalendarTypeFilter.vue'


require('vue-simple-calendar/static/css/default.css')

export default {
  name: 'DashboardCalendarView',
  components: {
    CalendarView,
    CalendarViewHeader,
    DashboardCalendarTypeFilter,
  },
  data () {
    let initialDate = dayjs(this.$router.currentRoute.query.from_date)
    return {
      showDate: initialDate.isValid() ? initialDate.toDate() : new Date(),
    }
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapFields('dashboardViews', [
      'monthlyStartDate',
      'monthlyEndDate',
    ]),
    ...mapState('investments', [
      'investments',
    ]),
    ...mapState('schedules', [
      'clientScheduleTypeFilters',
      'clientScheduleOwnerFilters',
    ]),
    ...mapGetters('users', [
      'userNamesFromIds'
    ]),
    displayByTemplateIds () {
      return this.currentDashboardView.calendar_field_template_ids
    },
    showOnCardTemplateIds () {
      return this.currentDashboardView.display_on_card_template_ids
    },
    formattedInvestmentsForCalendar () {
      let results = []
      this.investments.forEach(investment => {
        results = results.concat(this.makeCardByFields(investment))
                         .concat(this.makeCardBySchedules(investment))
      })
      return results.sort((a, b) => a.startTime.localeCompare(b.startTime))
    },
    weeklyOrMonthly () {
      switch (this.currentDashboardView.view_layout) {
        case 'Monthly':
          return 'month'
        case 'Weekly':
          return 'week'
        default:
          return ''
      }
    },
    itemsToshowOnCardCount () {
      return this.showOnCardTemplateIds.length
    },
    heightByShowFields () {
      let remHeight = 7 + this.itemsToshowOnCardCount // base height for card, plus 1 rem per line
      return `${remHeight}rem`
    }
  },
  methods: {
    ...mapActions('investments', [
      'investmentDetailsOpen',
    ]),
    ...mapActions('dashboardViews', [
      'updateDashboardInvestments'
    ]),
    setShowDate (date) {
      this.showDate = date
    },
    updateDates (calendar) {
      if (this.monthlyStartDate !== dayjs(calendar.periodStart).format('YYYY-MM-DD')) { // only need to check start or end date
        this.monthlyStartDate = dayjs(calendar.periodStart).format('YYYY-MM-DD')
        this.monthlyEndDate   = dayjs(calendar.periodEnd).format('YYYY-MM-DD')
        if (this.$router.currentRoute.query.from_date !== this.monthlyStartDate) {
          let newParams = {
            ...this.$router.currentRoute.query,
            ...{from_date: this.monthlyStartDate, to_date: this.monthlyEndDate}
          }
          this.$router.replace({ query: newParams })
        }
        this.updateDashboardInvestments()
      } else {
        // same month picked again: don't need to hit the api again
      }
    },
    updateEvent (date) {
      date.originalItem.id = date.originalItem.investment_id
      this.investmentDetailsOpen(date.originalItem.investment)
    },
    selectedFields (customFields) {
     return customFields.filter(field => this.displayByTemplateIds.includes(field.id))
    },
    selectedShowOnCardFields (customFields) {
      return customFields.filter(field => field.value && this.showOnCardTemplateIds.includes(field.id))
    },
    selectedSchedules (schedules) {
     return schedules.filter(schedule => this.clientScheduleTypeFilters.includes(schedule.schedule_type))
    },
    dashboardDisplayOnCardString (investment) {
      let fieldsString = ''
      let valueString = ''
      this.selectedShowOnCardFields(investment.custom_fields).forEach(field => {
        valueString = field.value
        fieldsString += '\n' + field.custom_field_name + ': ' + valueString
      })
      return fieldsString
    },
    cardTitleString (investment) {
      return investment.investment_name
    },
    scheduleString (schedule) {
      let userString     = (schedule.users)       ? `${schedule.users.map(user => user.name).join(", ")}\n` : ''
      let endTimeString  = (schedule.end_time)    ? `~${schedule.end_time}`    : ' '
      let locationString = (schedule.location)    ? `@ ${schedule.location}`   : ''
      let detailsString  = (schedule.description) ? `: ${schedule.description}` : ''
      return `${userString}(${schedule.schedule_type}) ${schedule.start_time}${endTimeString} ${locationString}${detailsString}`
    },
    makeCardByFields (investment) {
      let fieldCardsOnCalendar = []
      let dates = []
      let tempResult = {}

      this.selectedFields(investment.custom_fields).forEach(field => {
        if (field.value) {
          tempResult = { investment_id: investment.id }
          tempResult['title'] = `<strong class='truncate'>${this.cardTitleString(investment)}</strong>\n(${field.custom_field_name})${this.dashboardDisplayOnCardString(investment)}`
          if (field.field_type === 'date-range') {
            dates = field.value.split(',')
              tempResult['startDate'] = new Date(dates[0])
              tempResult['endDate'] = new Date(dates[1])
          } else {
            tempResult['startDate'] = new Date(field.value)
            tempResult['startTime'] = '00:00'
          }
          tempResult['investment'] = investment
          fieldCardsOnCalendar.push(tempResult)
        }
      })
      return fieldCardsOnCalendar
    },
    makeCardBySchedules (investment) {
      let scheduleCardsOnCalendar = []
      let tempResult = {}
      let filteredOwnerIds = this.clientScheduleOwnerFilters.map(owner => owner.id)
      let filteredByOwner = investment.schedules.filter(schedule => {
        return intersection(schedule.user_ids, filteredOwnerIds).length > 0 || (schedule.user_ids.length === 0 && filteredOwnerIds.includes(0))
      })
      this.selectedSchedules(filteredByOwner).forEach(schedule => {
        if (schedule.schedule_date) {
          tempResult = {investment_id: investment.id}
          tempResult['title'] = `<strong class='truncate'>${this.cardTitleString(investment)}</strong>\n${this.scheduleString(schedule)} ${this.dashboardDisplayOnCardString(investment)}`
          tempResult['startDate'] = new Date(schedule.schedule_date)
          tempResult['startTime'] = schedule.start_time
          tempResult['investment'] = investment
          scheduleCardsOnCalendar.push(tempResult)
        }
      })
      return scheduleCardsOnCalendar
    },
  }
}
</script>

<style lang='scss'>
.dashboard-calendar-view {
  .cv-item {
    white-space: break-spaces;
  }
  .cv-wrapper div {
    @apply text-sm;
  }

  .cv-header {
    @apply p-4 flex flex-row justify-end border-0;
  }

  .cv-header .periodLabel {
    @apply  block flex-grow text-left text-gray-800 font-semibold;
  }

  .cv-header button {
    @apply p-2 border-0;
  }

  .cv-header button:hover {
    @apply bg-blue-200;
  }

  .cv-header-day {
    @apply py-4 uppercase text-gray-800 font-semibold opacity-50;
    font-size: 10px;
  }

  .cv-day {
    @apply p-2 m-1 bg-white text-gray-800;
  }

  .cv-day,
  .cv-header-day,
  .cv-header-days,
  .cv-item,
  .cv-week,
  .cv-weeks {
    @apply border-0;
  }

  .outsideOfMonth {
    @apply bg-gray-100 text-gray-700;
  }

  .past {
    @apply opacity-50 text-gray-700;
  }

  .today {
    @apply border border-blue-300 shadow-md rounded-md;
  }

  .cv-item.span1 {
    @apply mx-2 px-1 text-xs rounded-md text-gray-600 border border-gray-200;
    background-color: #F5FBFD;
    width: calc((100% / 7) - 1.0rem);
    line-height: 1.15rem;
  }

  .calendar-view {
    min-width: 1600px;
  }
}

@media print {
  .dashboard-calendar-view {
    .calendar-view {
      min-width: 1000px;
    }
  }

}
</style>
