<template>
<div
  class='weekly-view-card border rounded-md bg-white'
  :class='previousDayStyle'>
  <div v-if='card.type === "schedule"'
    class='schedule-card'>
    <div class='font-semibold text-gray-900'>
      {{scheduleUsersString(card.schedule)}}
    </div>
    <div class='flex flex-row-reverse justify-end gap-x-1'>
      <div class='text-gray-600'>
        : {{card.schedule.schedule_type}}
      </div>
      <div class='font-medium'>
        {{scheduleTimeString(card.schedule)}}
        <span class='opacity-75'>({{dayStringForIndex(weekdayIndex)}})</span>
      </div>
    </div>
    <div class='leading-4 mt-0.5 text-gray-600 text-xs'>
      @ {{card.schedule.location}}
    </div>
    <div v-for='(field, index) in displayFieldsOnCard(card.investment.custom_fields)'
      :key='`${weekdayIndex}-weekly-view-investment-${index}-field-${field.id}`'
      class='flex flex-row justify-start items-start gap-x-2 overflow-x-hidden text-xs mt-1'>
      <label class='text-gray-500'>{{field.custom_field_name}}:</label>
      <span class='text-gray-600'>{{field.value}}</span>
    </div>
    <div class='leading-4 mt-0.5 text-gray-600 text-xs'>
      {{card.schedule.description}}
    </div>
    <h3 class='uppercase'>{{cardTitleString}}</h3>
  </div>

  <div v-else
    class=''>
    <h3 class='uppercase'>{{cardTitleString}}</h3>
    <div v-for='(field, index) in displayFieldsOnCard(card.investment.custom_fields)'
      :key='`${weekdayIndex}-weekly-view-investment-${index}-field-${field.id}`'
      class='flex flex-row justify-start items-start gap-x-2 overflow-x-hidden text-xs mt-1'>
      <label class='text-gray-500'>{{field.custom_field_name}}:</label>
      <span class='text-gray-600'>{{field.value}}</span>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs          from 'dayjs'
import StringHelpers  from '@/utils/string-helpers'


export default {
  name: 'DashboardWeeklyViewCard',
  props: [
    'card',
    'weekdayIndex',
  ],
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    cardTitleString () {
      return this.card.investment.investment_name
    },
    previousDayStyle () {
      return (dayjs(this.card.startDate).isBefore(dayjs(), 'day')) ? 'opacity-50' : ''
    }
  },
  methods: {
    dayStringForIndex (index) {
      return StringHelpers.dayStringForIndex(index)
    },
    scheduleUsersString (schedule) {
      return (schedule.users) ? `${schedule.users.map(user => user.name).join(", ")}` : ''
    },
    scheduleTimeString (schedule) {
      let endTimeString = (schedule.end_time) ? `~${schedule.end_time}` : ''
      return `${schedule.start_time}${endTimeString}`
    },
    displayFieldsOnCard (fields) {
      return fields.filter(field => field.value)
    },
  }
}
</script>

<style lang='scss' scoped>
.weekly-view-card {
  @apply text-sm p-2;
}

@media print { 
  .weekly-view-card {
    font-size: 11px;
    break-inside: avoid;
  }
  .weekly-view-card.text-xs {
    font-size: 9px !important;
  }
}

</style>
